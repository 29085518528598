import { DynamicContentInput } from 'mid-types';
import { ConfigurableProductActions, ConfigurableProductActionTypes } from './dataStore.actions';
import { ConfigurableProductPropertiesMap } from './dataStore.types';

export const configurableProductReducer = (
  configurableProductPropertiesMap: ConfigurableProductPropertiesMap,
  action: ConfigurableProductActions,
): ConfigurableProductPropertiesMap => {
  switch (action.type) {
    case ConfigurableProductActionTypes.UPDATE_INPUT: {
      const clonedInputsMap = new Map(configurableProductPropertiesMap.inputs);
      clonedInputsMap.set(action.payload.name, action.payload);

      return { ...configurableProductPropertiesMap, inputs: clonedInputsMap };
    }
    case ConfigurableProductActionTypes.INITIALIZE_CONFIGURABLE_PRODUCT_PAYLOAD: {
      const { inputs, outputs } = action.payload;

      return {
        outputs,
        inputs: inputs.reduce((acc: Map<string, DynamicContentInput>, input: DynamicContentInput) => {
          acc.set(input.name, input);
          return acc;
        }, new Map()),
      };
    }
    case ConfigurableProductActionTypes.UPDATE_INPUT_WITH_CODE_RUNNER: {
      const inputs = action.payload;

      const inputMaps = inputs.reduce((acc: Map<string, DynamicContentInput>, input: DynamicContentInput) => {
        acc.set(input.name, input);
        return acc;
      }, new Map());

      return { ...configurableProductPropertiesMap, inputs: inputMaps };
    }
    default:
      throw new Error('No Configurable Product Properties action found');
  }
};
