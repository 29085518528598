import { getDesktopApplicationVersionNumber, InputRule } from 'mid-addin-lib';
import { getContentFromObjectKey } from 'mid-addin-lib/utils/cloudStorage';
import { inputsRulesToTemplateRules } from 'mid-addin-lib/utils/tools';
import { NotificationContext, NOTIFICATION_STATUSES, useBase64Thumbnail } from 'mid-react-common';
import { DynamicContentProduct, TemplateRule } from 'mid-types';
import { CustomError, logError, ProductError } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import text from 'revit.text.json';
import DataContext from '../context/Data/Data.context';
import { initialProductTemplate } from '../context/Data/dataStore';
import NavigationContext from '../context/Navigation/Navigation.context';
import { Screens } from '../context/Navigation/navigationStore';

interface useProductSelectionScreenState {
  thumbnailInBase64: string | undefined;
  thumbnailLoading: boolean;
  thumbnailError: string | undefined;
  productLoading: boolean;
  handleCustomizeClick: () => void;
}

interface useProductSelectionScreenProps {
  selectedProduct: DynamicContentProduct | undefined;
}

export const useProductSelectionScreen = ({
  selectedProduct,
}: useProductSelectionScreenProps): useProductSelectionScreenState => {
  const { setCurrentScreen } = useContext(NavigationContext);
  const { currentProduct, setCurrentProduct } = useContext(DataContext);
  const { showNotification } = useContext(NotificationContext);

  const [productLoading, setProductLoading] = useState(false);

  useEffect(() => {
    if (selectedProduct) {
      setCurrentProduct(selectedProduct);
    } else {
      setCurrentProduct(initialProductTemplate);
    }
  }, [selectedProduct, setCurrentProduct]);

  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    currentProduct.tenancyId,
    currentProduct.thumbnail,
  );

  const handleCustomizeClick = async () => {
    try {
      setProductLoading(true);

      //Validate and load rules before opening product
      await checkApplicationVersion(currentProduct);
      const productRules = await checkAndDownloadProductRulesFromKey(currentProduct);

      // If product has rules downloaded with rulesKey, update rules property
      if (productRules) {
        setCurrentProduct({ ...currentProduct, rules: productRules });
      }

      //If succeeded go to next screen
      setProductLoading(false);
      setCurrentScreen(Screens.PRODUCT_CUSTOMIZATION);
    } catch (err) {
      if (err instanceof CustomError) {
        logError(err);
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
        setProductLoading(false);
      } else {
        logError(err);
        showNotification({
          message: text.failedToLoadProduct,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }
  };

  return {
    thumbnailInBase64,
    thumbnailLoading,
    thumbnailError,
    productLoading,
    handleCustomizeClick,
  };
};

const checkApplicationVersion = async (currentProduct: DynamicContentProduct): Promise<void> => {
  const applicationVersionNumber = await getDesktopApplicationVersionNumber();
  if (
    applicationVersionNumber !== undefined &&
    parseInt(currentProduct.context.engine.version) > parseInt(applicationVersionNumber)
  ) {
    throw new ProductError(text.incompatibleProduct, {
      currentProduct,
    });
  }
};

const checkAndDownloadProductRulesFromKey = async (
  currentProduct: DynamicContentProduct,
): Promise<TemplateRule | undefined> => {
  if (currentProduct.rulesKey) {
    const inputRules = await getContentFromObjectKey<InputRule[]>(currentProduct.tenancyId, currentProduct.rulesKey);
    if (inputRules && inputRules.length > 0) {
      return inputsRulesToTemplateRules(inputRules);
    }
  }
};

export default useProductSelectionScreen;
