import { isEqual } from 'lodash';
import { getVariantOutputs, insertRFA, OutputType, postVariant } from 'mid-addin-lib';
import { downloadFailedVariantLogFile } from 'mid-addin-lib/utils/variants';
import {
  codeRunner,
  ModalContext,
  NotificationContext,
  NOTIFICATION_STATUSES,
  PollState,
  transformToVariantPayload,
  useAsyncPollingWithArgs,
  useBase64Thumbnail,
} from 'mid-react-common';
import {
  DynamicContentInput,
  DynamicContentVariant,
  DynamicContentVariantOutput,
  PostVariantPayload,
  TemplateOutput,
  VariantOutputStatus,
  VariantOutputType,
} from 'mid-types';
import { logError, ProductError } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import text from 'revit.text.json';
import DataContext from '../context/Data/Data.context';
import { addQuotesToTextParameters } from 'mid-addin-lib/utils/tools';

export const shouldVariantOutputsPollingContinue = (variant: DynamicContentVariant): boolean =>
  !variant.outputs.every((output) =>
    [VariantOutputStatus.SUCCESS, VariantOutputStatus.CANCELLED, VariantOutputStatus.FAILED].includes(output.status),
  );

const variantStatusPollingCallback = async (
  tenancyId?: string,
  contentId?: string,
  variantId?: string,
): Promise<DynamicContentVariantOutput[] | undefined> => {
  if (tenancyId && contentId && variantId) {
    return await getVariantOutputs(tenancyId, contentId, variantId);
  }
};

interface UseProductCustomizationScreenState {
  isPreviewLoading: boolean;
  isRFAGenerationLoading: boolean;
  isFormInitializing: boolean;
  productCustomizationFormError: Error | undefined;
  shouldThumbnailBoxShowLoader: boolean;
  inputs: DynamicContentInput[];
  productRFAOutput?: TemplateOutput;
  thumbnailInBase64: string | undefined;
  thumbnailError: string | undefined;
  isFormDataValid: boolean;
  setIsFormDataValid: (isFormDataValid: boolean) => void;
  handleInsertRFA: () => void;
  handleUpdatePreviewClick: () => Promise<void>;
  handleResetClick: () => void;
  handleInputUpdate: (inputsToUpdate: DynamicContentInput) => void;
}

enum PostVariantTrigger {
  UpdatePreview = 'updatePreview',
  InsertRfa = 'insertRfa',
}

export const useProductCustomizationScreen = (): UseProductCustomizationScreenState => {
  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);
  const {
    configurableProductProperties,
    currentProduct,
    resetConfigurableProductProperties,
    updateConfigurableProductInput,
    updateConfigurableProductInputWithCodeRunner,
  } = useContext(DataContext);
  const [isFormInitializing, setIsFormInitializing] = useState(true);
  const [productCustomizationFormError, setProductCustomizationFormError] = useState<Error | undefined>(undefined);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isRFAGenerationLoading, setIsRFAGenerationLoading] = useState(false);
  const [postVariantTrigger, setPostVariantTrigger] = useState<PostVariantTrigger>();
  const [savedVariant, setSavedVariant] = useState<DynamicContentVariant | undefined>();
  const [isFormDataValid, setIsFormDataValid] = useState(true);
  const [inputsEnhancedByCodeRunner, setInputsEnhancedByCodeRunner] = useState<DynamicContentInput[]>([]);
  const {
    data: polledVariant,
    pollState,
    startPolling: startPollingVariantStatus,
  } = useAsyncPollingWithArgs<DynamicContentVariant>(async (): Promise<DynamicContentVariant | undefined> => {
    if (!savedVariant) {
      return;
    }
    const outputs = await variantStatusPollingCallback(
      savedVariant?.tenancyId,
      savedVariant?.contentId,
      savedVariant?.variantId,
    );

    const polled: DynamicContentVariant = { ...savedVariant };
    polled.outputs = outputs ?? [];

    // thumbnail
    polled.thumbnail =
      (polled.outputs && polled.outputs.find((o) => o.type === VariantOutputType.THUMBNAIL)?.urn) ?? polled.thumbnail;

    return polled;
  }, shouldVariantOutputsPollingContinue);
  const productRFAOutput = configurableProductProperties.outputs.find(
    (output: TemplateOutput) => output.type === OutputType.RFA,
  );

  const [argsForUseBase64Thumbnail, setArgsForUseBase64Thumbnail] = useState<{
    tenancyId?: string;
    thumbnail?: string;
  }>({});
  const { thumbnailInBase64, thumbnailLoading, thumbnailError } = useBase64Thumbnail(
    argsForUseBase64Thumbnail.tenancyId,
    argsForUseBase64Thumbnail.thumbnail,
  );

  useEffect(() => {
    setArgsForUseBase64Thumbnail({
      tenancyId: currentProduct.tenancyId,
      thumbnail: polledVariant?.thumbnail || savedVariant?.thumbnail || currentProduct.thumbnail,
    });
  }, [currentProduct.tenancyId, currentProduct.thumbnail, polledVariant?.thumbnail, savedVariant?.thumbnail]);

  const areVariantOutputsReady = (outputs: DynamicContentVariantOutput[]): Boolean =>
    outputs.every((output) => output.status === VariantOutputStatus.SUCCESS);

  const pollingVariantAfterUpdatePreview = useCallback(async () => {
    if (pollState === PollState.POLLING) {
      setIsPreviewLoading(true);
    }

    if (pollState === PollState.FINISHED) {
      if (polledVariant && areVariantOutputsReady(polledVariant.outputs)) {
        showNotification({
          message: text.succeedUpdatePreview,
          severity: NOTIFICATION_STATUSES.SUCCESS,
        });
      } else {
        showNotification({
          message: text.failUpdatePreview,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
      setIsPreviewLoading(false);
      setPostVariantTrigger(undefined);
    }

    if (pollState === PollState.ERROR) {
      setIsPreviewLoading(false);
      setPostVariantTrigger(undefined);
      showNotification({
        message: text.failUpdatePreview,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [pollState, polledVariant, showNotification]);

  const handleVariantFailure = useCallback(async () => {
    if (!polledVariant) {
      return;
    }

    try {
      const downloadLocation = await downloadFailedVariantLogFile(
        polledVariant.tenancyId,
        polledVariant.contentId,
        polledVariant.variantId,
        currentProduct.name,
      );

      showNotification({
        message: text.successDownloadVariantLog + downloadLocation,
        severity: NOTIFICATION_STATUSES.WARNING,
        // keep the notification alive until user manually closes it (to be able to copy that log path)
        autoDismiss: undefined,
      });
    } catch (downloadFailedVariantLogError) {
      logError(downloadFailedVariantLogError);
      showNotification({
        message: text.failDownloadVariantLog,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [polledVariant, showNotification, currentProduct.name]);

  const pollingVariantAfterInsertRFA = useCallback(async () => {
    if (pollState === PollState.POLLING) {
      setIsRFAGenerationLoading(true);
    }

    if (pollState === PollState.FINISHED) {
      setIsRFAGenerationLoading(false);
      setPostVariantTrigger(undefined);
      if (polledVariant) {
        try {
          // Insert RFA
          await insertRFA(polledVariant);
          showNotification({
            message: text.succeedInsert,
            severity: NOTIFICATION_STATUSES.SUCCESS,
          });
        } catch (err: any) {
          logError(err);
          showNotification({
            message: text.failInsert,
            severity: NOTIFICATION_STATUSES.ERROR,
          });

          handleVariantFailure();
        }
      } else {
        showNotification({
          message: text.failInsert,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    }

    if (pollState === PollState.ERROR) {
      setIsRFAGenerationLoading(false);
      setPostVariantTrigger(undefined);
      showNotification({
        message: text.failUpdatePreview,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [pollState, polledVariant, showNotification, handleVariantFailure]);

  useEffect(() => {
    if (postVariantTrigger) {
      if (postVariantTrigger === PostVariantTrigger.InsertRfa) {
        pollingVariantAfterInsertRFA();
      } else {
        pollingVariantAfterUpdatePreview();
      }
    }
  }, [pollingVariantAfterInsertRFA, pollingVariantAfterUpdatePreview, postVariantTrigger]);

  const postVariantAndBeginPolling = async (
    tenancyId: string,
    contentId: string,
    postVariantPayload: PostVariantPayload,
  ): Promise<void> => {
    const savedVariant: DynamicContentVariant = await postVariant(tenancyId, contentId, postVariantPayload);
    setSavedVariant(savedVariant);
    startPollingVariantStatus();
  };

  const updateProductPreview = async () => {
    try {
      if (!currentProduct.contentId) {
        throw new ProductError(text.previewFailedProductDoesNotContainContentId, {
          currentProduct,
        });
      }
      setIsPreviewLoading(true);

      const productPropertiesWithQuotes = {
        ...configurableProductProperties,
        inputs: addQuotesToTextParameters(configurableProductProperties.inputs),
      };

      const postVariantPayload: PostVariantPayload = transformToVariantPayload(productPropertiesWithQuotes);
      await postVariantAndBeginPolling(currentProduct.tenancyId, currentProduct.contentId, postVariantPayload);
      setPostVariantTrigger(PostVariantTrigger.UpdatePreview);
    } catch (err) {
      setIsPreviewLoading(false);

      logError(err);
      showNotification({
        message: text.failUpdatePreview,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const handleUpdatePreviewClick = async () => {
    if (!dontShowAgain) {
      setModalState({
        isOpen: true,
        title: text.updatePreviewNow,
        message: text.updatePreviewModalMessage,
        confirmButtonLabel: text.updatePreview,
        cancelButtonLabel: text.dontUpdate,
        dontShowAgainMessage: text.dontShowAgainMessage,
        onConfirmCallback: updateProductPreview,
      });
    } else {
      await updateProductPreview();
    }
  };

  const handleResetClick = (): void => {
    resetConfigurableProductProperties();
  };

  const handleInsertRFA = async () => {
    // Transform to variant payload
    const productPropertiesWithQuotes = {
      ...configurableProductProperties,
      inputs: addQuotesToTextParameters(configurableProductProperties.inputs),
    };
    const postVariantPayload = transformToVariantPayload(productPropertiesWithQuotes);

    // Post Variant
    try {
      if (!currentProduct.contentId) {
        throw new ProductError(text.insertFailedMissingContentId, {
          currentProduct,
        });
      }

      setIsRFAGenerationLoading(true);
      await postVariantAndBeginPolling(currentProduct.tenancyId, currentProduct.contentId, postVariantPayload);
      setPostVariantTrigger(PostVariantTrigger.InsertRfa);
    } catch (err) {
      logError(err);
      showNotification({
        message: text.failInsert,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const handleInputUpdate = (inputsToUpdate: DynamicContentInput) => {
    updateConfigurableProductInput(inputsToUpdate);
  };

  const enhanceInputsWithCodeRunnerRules = useCallback(
    (codeRunnerRuleFromProduct: string | undefined, inputs: DynamicContentInput[]): DynamicContentInput[] | null => {
      try {
        if (!codeRunnerRuleFromProduct) {
          return null;
        }
        const { result: enhancedProductInputs } = codeRunner(codeRunnerRuleFromProduct, inputs);

        if (!enhancedProductInputs || !enhancedProductInputs.length) {
          logError(text.failedToLoadInputs, { inputs: configurableProductProperties.inputs });
          throw Error(text.failedToLoadInputs);
        }

        return enhancedProductInputs;
      } catch (e) {
        logError(e, { inputs: configurableProductProperties.inputs });
        setProductCustomizationFormError(Error(text.failedToLoadInputs));
        return null;
      } finally {
        setIsFormInitializing(false);
      }
    },
    [configurableProductProperties.inputs],
  );

  const codeRunnerCode = currentProduct.rules?.currentRule?.code;
  useEffect(() => {
    // The codeRunner needs to update the inputs
    // before we render the form + needs to run on every input update
    // According to the isEqual logic, inputsEnhancedByCodeRunner is
    // an empty array when this component is first mounted,
    // but the configurableProductProperties.inputs value is populated
    // so the codeRunner is run once before the form is populated & once on every input update.
    if (!isEqual(inputsEnhancedByCodeRunner, configurableProductProperties.inputs) && codeRunnerCode) {
      const updatedInputs = enhanceInputsWithCodeRunnerRules(
        currentProduct.rules?.currentRule?.code,
        configurableProductProperties.inputs,
      );
      if (updatedInputs) {
        setInputsEnhancedByCodeRunner(updatedInputs);
        updateConfigurableProductInputWithCodeRunner(updatedInputs);
      }
    }
  }, [
    codeRunnerCode,
    configurableProductProperties.inputs,
    currentProduct.rules?.currentRule?.code,
    enhanceInputsWithCodeRunnerRules,
    inputsEnhancedByCodeRunner,
    updateConfigurableProductInputWithCodeRunner,
  ]);

  return {
    isPreviewLoading,
    isRFAGenerationLoading,
    isFormInitializing,
    productCustomizationFormError,
    shouldThumbnailBoxShowLoader: isPreviewLoading || isRFAGenerationLoading || thumbnailLoading,
    inputs: inputsEnhancedByCodeRunner,
    productRFAOutput,
    isFormDataValid,
    setIsFormDataValid,
    handleInsertRFA,
    handleUpdatePreviewClick,
    handleResetClick,
    handleInputUpdate,
    thumbnailInBase64,
    thumbnailError,
  };
};
