import { SummaryTable, SummaryTableRow, TableTitle } from 'mid-react-common';
import text from 'revit.text.json';

interface ProductDetailsProps {
  title: string;
  productCategory: string;
  productFamily: string;
  productLevelOfDetail: string;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  title,
  productCategory,
  productFamily,
  productLevelOfDetail,
}): JSX.Element => {
  const productDetailsData: SummaryTableRow[] = [
    {
      title: text.categoryProductDetails,
      value: productCategory,
    },
    {
      title: text.familyProductDetails,
      value: productFamily,
    },
    {
      title: text.levelOfDetailProductDetails,
      value: productLevelOfDetail,
    },
  ];

  return (
    <>
      <TableTitle>{title}</TableTitle>
      <SummaryTable data={productDetailsData} />
    </>
  );
};
