import { HeaderWrapper, RevitAddInMoniker, TopHeader, VersionInfo } from 'mid-react-common';
import { generateAddInVersionForDisplay, logError } from 'mid-utils';
import React, { useContext, useEffect, useState } from 'react';
import DataContext from '../../context/Data/Data.context';
import { initialProductTemplate } from '../../context/Data/dataStore';
import NavigationContext from '../../context/Navigation/Navigation.context';
import { Screens } from '../../context/Navigation/navigationStore';
import CustomizeProduct from './SubHeaders/CustomizeProduct';
import Initial from './SubHeaders/Initial';

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const { setCurrentProduct } = useContext(DataContext);
  const [addinVersionTag, setAddinVersionTag] = useState<string | undefined>(undefined);

  useEffect(() => {
    generateAddInVersionForDisplay(RevitAddInMoniker)
      .then((addinVersion) => {
        setAddinVersionTag(addinVersion);
      })
      .catch((error) => {
        logError(error);
      });
  }, []);

  const handleChangeProductClick = () => {
    setCurrentScreen(Screens.PRODUCT_SELECTION);
    setCurrentProduct(initialProductTemplate);
  };

  return (
    <HeaderWrapper>
      <TopHeader>
        {currentScreen === Screens.PRODUCT_SELECTION && <Initial />}
        {currentScreen === Screens.PRODUCT_CUSTOMIZATION && (
          <CustomizeProduct handleChangeProductClick={handleChangeProductClick} />
        )}
        <VersionInfo webVersion={process.env.REACT_APP_RVTW_VERSION} addinVersion={addinVersionTag} />
      </TopHeader>
    </HeaderWrapper>
  );
};

export default Header;
