import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Header from './components/Header/Header';
import { ScreenManager } from './components/ScreenRenderer/ScreenRenderer';
import { useNavigationStore } from './context/Navigation/navigationStore';
import NavigationContext from './context/Navigation/Navigation.context';
import DataContext from './context/Data/Data.context';
import { useStore } from './context/Data/dataStore';
import {
  midTheme,
  Main,
  ConfirmationModal,
  ModalContext,
  useModalStore,
  ErrorBoundary,
  EnvironmentInfo,
  UnderMaintenance,
  ErrorFallback,
  RevitWebComponentMoniker,
  NotificationsProvider,
  createBugsnagErrorBoundaryComponent,
  useNotificationStore,
  UserAnalyticsProvider,
} from 'mid-react-common';

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  moniker: RevitWebComponentMoniker,
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY_ADDINS || '',
  appVersion: process.env.REACT_APP_RVTW_VERSION || 'Not provided',
});

const App: React.FC = (): JSX.Element => {
  const { enableMaintenanceMode } = useFlags();
  const navigationStore = useNavigationStore();
  const dataStore = useStore();
  const modalStore = useModalStore();
  const notificationStore = useNotificationStore();

  const handleResetAppState = (): void => window.location.reload();

  if (enableMaintenanceMode) {
    return (
      <ThemeProvider theme={midTheme}>
        <UnderMaintenance />
      </ThemeProvider>
    );
  }

  const appTree = (
    <ThemeProvider theme={midTheme}>
      <ModalContext.Provider value={modalStore}>
        <ConfirmationModal />
        <NotificationsProvider store={notificationStore}>
          <UserAnalyticsProvider>
            <NavigationContext.Provider value={navigationStore}>
              <DataContext.Provider value={dataStore}>
                <Header />
                <Main>
                  <EnvironmentInfo hostname={window.location.host} />
                  <ScreenManager />
                </Main>
              </DataContext.Provider>
            </NavigationContext.Provider>
          </UserAnalyticsProvider>
        </NotificationsProvider>
      </ModalContext.Provider>
    </ThemeProvider>
  );

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      {appTree}
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState}>{appTree}</ErrorBoundary>
  );
};

export default App;
