import { LeftSection, Subtitle } from 'mid-react-common';
import text from 'revit.text.json';

const Initial: React.FC = (): JSX.Element => (
  <LeftSection>
    <Subtitle>{text.subHeaderTitleInitial}</Subtitle>
  </LeftSection>
);

export default Initial;
