import Button from '@adsk/alloy-react-button';
import { midTheme, RightButton } from 'mid-react-common';
import text from 'revit.text.json';
import { Wrapper } from './InsertProductBar.styles';

interface InsertProductBarProps {
  handleInsertRFA: () => void;
  isLoading: boolean;
  isPreviewLoading: boolean;
  isFormDataValid: boolean;
}

const InsertProductBar: React.FC<InsertProductBarProps> = ({
  handleInsertRFA,
  isLoading,
  isPreviewLoading,
  isFormDataValid,
}): JSX.Element => (
  <Wrapper>
    <RightButton
      data-testid="insert-button"
      width={midTheme.var.narrowInputControl}
      variant={Button.VARIANTS.PRIMARY}
      onClick={handleInsertRFA}
      loading={isLoading}
      disabled={isPreviewLoading || !isFormDataValid}
    >
      {text.insert}
    </RightButton>
  </Wrapper>
);

export default InsertProductBar;
