import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${({ theme }) => `${theme.var.footerHeight}px`};
  padding: ${({ theme }) => `0 ${theme.var.paddingBase * 2}px`};
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 2px solid ${({ theme }) => theme.colors.primary.black};
  background-color: ${({ theme }) => theme.colors.primary.white};
`;
