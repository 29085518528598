import React from 'react';
import { RightButton } from '../../global/styles/Common.styles';
import { LeftSection, RightSection, Title } from '../../global/styles/Header.styles';
import text from '../../mid-react-common.text.json';
import { CustomizationWrapper } from './ProductCustomization.styles';

interface ProductCustomizationHeaderState {
  isPreviewLoading: boolean;
  isInsertLoading: boolean;
  isFormDataValid: boolean;
  handleResetClick: () => void;
  handleUpdatePreviewClick: () => void;
}

const ProductCustomizationHeader: React.FC<ProductCustomizationHeaderState> = ({
  isPreviewLoading,
  isInsertLoading,
  isFormDataValid,
  handleResetClick,
  handleUpdatePreviewClick,
}) => (
  <CustomizationWrapper>
    <LeftSection>
      <Title>{text.revitCustomize}</Title>
    </LeftSection>
    <RightSection>
      <RightButton onClick={handleResetClick} disabled={isPreviewLoading || isInsertLoading}>
        {text.revitReset}
      </RightButton>
      <RightButton onClick={handleUpdatePreviewClick} disabled={isPreviewLoading || isInsertLoading || !isFormDataValid}>
        {text.revitUpdatePreview}
      </RightButton>
    </RightSection>
  </CustomizationWrapper>
);

export default ProductCustomizationHeader;
