import Button from '@adsk/alloy-react-button';
import { LeftSection, RightButton, RightSection, Subtitle } from 'mid-react-common';
import text from 'revit.text.json';

interface CustomizeProductHeaderProps {
  handleChangeProductClick: () => void;
}

const CustomizeProduct: React.FC<CustomizeProductHeaderProps> = ({ handleChangeProductClick }): JSX.Element => (
  <>
    <LeftSection>
      <Subtitle>{text.productCustomization}</Subtitle>
    </LeftSection>
    <RightSection>
      <RightButton
        data-testid="change-product-button"
        variant={Button.VARIANTS.SECONDARY}
        onClick={handleChangeProductClick}
        size={Button.SIZES.SMALL}
      >
        {text.changeProduct}
      </RightButton>
    </RightSection>
  </>
);

export default CustomizeProduct;
