import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CustomizeContainer = styled('div')`
  margin: 0 ${({ theme }) => `${theme.var.marginBase * 2}px`};
  min-width: ${({ theme }) => `${theme.var.wideInputControl}px`};
  width: 50%;
`;

export const PreviewContainer = styled('div')`
  ${({ theme }) => theme.typography.bodyMedium};
  padding-bottom: ${({ theme }) => `${theme.var.paddingBase}px`};
  margin: 0 ${({ theme }) => `${theme.var.marginBase * 2}px`};
  width: 50%;
  height: auto;
  max-height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.footerHeight}px`});
  overflow: auto;
`;

export const PreviewLoadingTimeMessageWrap = styled('p')`
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;
